import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { APIMembership } from 'src/apis/membership';
import { APIError } from 'src/apis/error';
import { HttpStatusCode } from 'axios';
import { Memberships, Membership } from 'src/apis/type';

export const useRegisterMembershipMutationQuery = () => {
    return useMutation({
        mutationKey: ['@membership/mutation.register'],
        mutationFn: async (params: FormData) => {
            try {
                const result = await APIMembership.register(params);
                if (result.status === HttpStatusCode.Ok) {
                    return true;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
};

export const useUpdateMembershipMutationQuery = () => {
    return useMutation({
        mutationKey: ['@membership/mutation.update'],
        mutationFn: async (params: FormData) => {
            try {
                const result = await APIMembership.update(params);
                if (result.status === HttpStatusCode.Ok) {
                    return true;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
};

export const useUpdateMembershipAvailableMutationQuery = () => {
    return useMutation({
        mutationKey: ['@membership/mutation.register'],
        mutationFn: async (params: { id: string; isAvailable: boolean }) => {
            try {
                const result = await APIMembership.updateAvailable(params.id, params.isAvailable);
                if (result.status === HttpStatusCode.Ok) {
                    return true;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
};

export const useDeleteMembershipMutationQuery = () => {
    return useMutation({
        mutationKey: ['@membership/mutation.delete'],
        mutationFn: async (id: string) => {
            try {
                const result = await APIMembership.delete(id);
                if (result.status === HttpStatusCode.Ok) {
                    return true;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
};

export const useGetMembershQuery = () => {
    return useQuery({
        queryKey: ['@membership/lists'],
        queryFn: async () => {
            try {
                const result = await APIMembership.get();
                if (result.status == 200) {
                    return result.data as Memberships;
                } else {
                    return [];
                }
            } catch (e) {
                return [];
            }
        },

        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        refetchInterval: 30000
    });
};

export const useGetMembershByIdQuery = (id?: string | null) => {
    return useQuery({
        queryKey: ['@membership/id', id],
        queryFn: async () => {
            try {
                if (id) {
                    const result = await APIMembership.getById(id);
                    if (result.status == 200) {
                        return result.data as Membership;
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            } catch (e) {
                return null;
            }
        },

        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        refetchInterval: 30000
    });
};
