import axios, { AxiosRequestConfig } from 'axios';
import { ENVIRONMENT } from '../env';
import { User, UserType, UserLogin, KakaoInfo } from './type';
import { LoginProviderType, LoginProvider } from '@haechi-labs/face-types';

export class APINFT {
    static async getUserNFTs() {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/nft/user/nfts`,
            method: 'GET',
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static async getUserWalletValidate(wallet: string) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/nft/user/validate/wallet`,
            params: {
                wallet
            },
            method: 'GET',
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static async mintGenesisNFTs(wallet: string, type: string) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/nft/user/genesis/mint`,
            method: 'POST',
            data: {
                wallet,
                type
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }
}