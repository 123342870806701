import * as React from 'react';
import { FC, useRef, useState } from 'react';
import { Box, TextField, FormControl, InputLabel, Select, MenuItem, Button, Grid, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEventListStore } from 'src/store/event';
import { SmallTextField } from 'src/page/components/SmallTextField';
import { useAssetNamesQuery } from 'src/apis/hook/asset';

import dayjs from 'dayjs';
dayjs.locale('ko');

export const EventController: FC = () => {
    const { t } = useTranslation();
    const { search, setSearch, startDate, setStartDate, endDate, setEndDate, setPage } = useEventListStore();
    const [searchTemp, setSearchTextTemp] = useState<string>(search);
    const [startDateTemp, setStartDateTemp] = useState<Date | undefined>(startDate);
    const [endDateTemp, setEndDateTemp] = useState<Date | undefined>(endDate);
    const [assetId, setAssetId] = useState<string | undefined>(undefined);

    return (
        <Box width="100%" alignItems="center" display="flex" flexDirection="column" maxWidth="1024px">
            <Grid container justifyContent="center" spacing={1} sx={{ p: 1 }} rowGap={1}>
                <Grid item md={4} xs={6}>
                    <SmallTextField
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        label={t('names:title')}
                        variant="outlined"
                        size="small"
                        value={searchTemp}
                        onChange={(e) => setSearchTextTemp(e.target.value)}
                    />
                </Grid>
                <Grid item md={3} xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                        <DatePicker
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    InputProps: {
                                        sx: {
                                            height: '35px',
                                            fontSize: '13px'
                                        }
                                    },
                                    InputLabelProps: {
                                        sx: { fontSize: '13px' }
                                    }
                                }
                            }}
                            sx={{ width: '100%' }}
                            value={dayjs(startDateTemp)}
                            label={t('names:date')}
                            onChange={(date) => {
                                if (date) {
                                    setStartDateTemp(date.toDate());
                                }
                                return date;
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item md={3} xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                        <DatePicker
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    InputProps: {
                                        sx: {
                                            height: '35px',
                                            fontSize: '13px'
                                        }
                                    },
                                    InputLabelProps: {
                                        sx: { fontSize: '13px' }
                                    }
                                }
                            }}
                            sx={{ width: '100%' }}
                            value={dayjs(endDateTemp)}
                            label={t('names:date')}
                            onChange={(date) => {
                                if (date) {
                                    setEndDateTemp(date.toDate());
                                }
                                return date;
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item md={2} xs={12}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            if (startDateTemp && endDateTemp && startDateTemp <= endDateTemp) {
                                setSearch(searchTemp);
                                setStartDate(startDateTemp);
                                setEndDate(endDateTemp);
                                setPage(0);
                            }
                        }}
                    >
                        {t('names:search')}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};
