import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { APIReserves } from 'src/apis/reserve';
import { APIError } from 'src/apis/error';
import { HttpStatusCode } from 'axios';
import { Reserves } from 'src/apis/type';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; // UTC 플러그인 로드
import timezone from 'dayjs/plugin/timezone'; // 시간대 플러그인 로드


dayjs.locale('ko');
dayjs.extend(utc); // UTC 플러그인 확장
dayjs.extend(timezone); // 시간대 플러그인 확장
dayjs.tz.setDefault('Asia/Seoul');


export const useGetReservesQuery = (
    search?: string,
    asset?: string,
    startDate?: Date,
    endDate?: Date,
    page?: number,
    limit?: number
) => {
    return useQuery({
        queryKey: ['@reserve/get', search, asset, startDate, endDate, page, limit],
        queryFn: async () => {
            try {
                //date to yyyy-mm-dd
                let startDateText = '';
                if (startDate) {
                    startDateText = dayjs(startDate).format('YYYY-MM-DD');
                }
                let endDateText = '';
                if (endDate) {
                    endDateText = dayjs(endDate).format('YYYY-MM-DD');
                }
                const result = await APIReserves.get(search, asset, startDateText, endDateText, page, limit);
                if (result.status == 200) {
                    return result.data as Reserves;
                } else {
                    return [];
                }
            } catch (e) {
                return [];
            }
        },

        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
        refetchInterval: 10000
    });
};

export const useGetReservesCountQuery = (search?: string, asset?: string, startDate?: Date, endDate?: Date) => {
    return useQuery({
        queryKey: ['@reserve/get/count', search, asset, startDate, endDate],
        queryFn: async () => {
            try {
                //date to yyyy-mm-dd
                let startDateText = '';
                if (startDate) {
                    startDateText = startDate.toISOString().split('T')[0];
                }
                let endDateText = '';
                if (endDate) {
                    endDateText = endDate.toISOString().split('T')[0];
                }
                const result = await APIReserves.getCount(search, asset, startDateText, endDateText);
                if (result.status == 200) {
                    return Number(result.data);
                } else {
                    return 0;
                }
            } catch (e) {
                return 0;
            }
        },

        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true
    });
};

export const useUpdateReserveConfirmedMutate = () => {
    return useMutation({
        mutationKey: ['@reserve/update/confirmed'],
        mutationFn: async ({ id, confirmed }: { id: string; confirmed: boolean }) => {
            try {
                const result = await APIReserves.updateConfirmed(id, confirmed);
                if (result.status === HttpStatusCode.Ok) {
                    return true;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
};

export const useUpdateReserveCanceledMutate = () => {
    return useMutation({
        mutationKey: ['@reserve/update/canceled'],
        mutationFn: async ({ id, canceled }: { id: string; canceled: boolean }) => {
            try {
                const result = await APIReserves.updateCanceled(id, canceled);
                if (result.status === HttpStatusCode.Ok) {
                    return true;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
};
