import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { APIArchive } from 'src/apis/archive';
import { APIError } from 'src/apis/error';
import { HttpStatusCode } from 'axios';
import { Archive, Archives } from 'src/apis/type';

export const useRegisterArchiveMutationQuery = () => {
    return useMutation({
        mutationKey: ['@archive/mutation.register'],
        mutationFn: async (params: FormData) => {
            try {
                const result = await APIArchive.registerArchive(params);
                if (result.status === HttpStatusCode.Ok) {
                    return true;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
}

export const useDeleteArchiveMutationQuery = () => {
    return useMutation({
        mutationKey: ['@archive/mutation.delete'],
        mutationFn: async (id: string) => {
            try {
                const result = await APIArchive.deleteArchive(id);
                if (result.status === HttpStatusCode.Ok) {
                    return true;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
}

export const useGetArchivesQuery = (page: number, limit: number) => {
    return useQuery(
        {
            queryKey: ['@archive/archives', page, limit],
            queryFn: async () => {
                try {
                    const result = await APIArchive.getArchives(page, limit);
                    if (result.status == 200) {
                        return result.data as Archives;
                    } else {
                        return [];
                    }
                } catch (e) {
                    return [];
                }
            },

            refetchOnMount: true,
            refetchOnWindowFocus: true,
            refetchOnReconnect: true,
            refetchInterval: 30000,
        });
}

export const useGetArchivesCountQuery = () => {
    return useQuery(
        {
            queryKey: ['@archive/count'],
            queryFn: async () => {
                try {
                    const result = await APIArchive.getArchivesCount();
                    if (result.status == 200) {
                        return (result.data as { count: number }).count;
                    } else {
                        return 0;
                    }
                } catch (e) {
                    return 0;
                }
            },

            refetchOnMount: true,
            refetchOnWindowFocus: true,
            refetchOnReconnect: true,
            refetchInterval: 30000,
        });
}
