import { create } from 'zustand';
import { Event } from 'src/apis/type';
export interface EventListStore {
    selectedEvent: Event | null;
    search: string;
    startDate: Date | undefined;
    endDate: Date | undefined;
    page: number;
    limit: number;
    setLimit: (value?: number) => void;
    setPage: (value?: number) => void;
    setSearch: (value?: string) => void;
    setStartDate: (value?: Date) => void;
    setEndDate: (value?: Date) => void;
    setSelectedEvent: (value: Event | null) => void;
}

export const useEventListStore = create<EventListStore>((set) => ({
    selectedEvent: null,
    page: 0,
    limit: 20,
    search: '',
    startDate: new Date(),
    endDate: (() => {
        const newDate = new Date();
        newDate.setMonth(newDate.getMonth() + 1);
        return newDate;
    })(),
    setLimit: (value?: number) => set({ limit: value }),
    setPage: (value?: number) => set({ page: value }),
    setSearch: (value?: string) => set({ search: value }),
    setStartDate: (value?: Date) => set({ startDate: value }),
    setEndDate: (value?: Date) => set({ endDate: value }),
    setSelectedEvent: (value: Event | null) => {
        set(() => ({ selectedEvent: value }));
    }
}));
