import * as React from 'react';
import { FC, useState, useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, Chip, MenuItem, Menu, Button, IconButton } from '@mui/material';
import { useReserveListStore } from 'src/store/reserve';
import { Reserve, SpaceType } from 'src/apis/type';
import { useTranslation } from 'react-i18next';
import {
    useGetReservesQuery,
    useGetReservesCountQuery,
    useUpdateReserveCanceledMutate,
    useUpdateReserveConfirmedMutate
} from 'src/apis/hook/reserve';

import { convertMinutesToTimeFromatString } from 'src/apis/ui';
import { useQrCodeCheckUI } from 'src/store/ui';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CheckIcon from '@mui/icons-material/Check';
import { comfirmDialog } from '../components/SimpleConfirmDialog';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReserveDetailDialog } from 'src/page/components/ReserveDetailDialog';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useNavigate } from 'react-router-dom';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

import dayjs from 'dayjs';
import 'dayjs/locale/ko';

export const ReserveList: FC = () => {
    const matches = useMediaQuery('(min-width:700px)');
    const navigate = useNavigate();

    const { page, limit, search, asset, startDate, endDate, setPage, setLimit } = useReserveListStore();
    const { mutateAsync: canceled } = useUpdateReserveCanceledMutate();
    const { mutateAsync: confirmed } = useUpdateReserveConfirmedMutate();

    const { data: reserves, refetch } = useGetReservesQuery(search, asset, startDate, endDate, page, limit);
    const { data: count } = useGetReservesCountQuery(search, asset, startDate, endDate);

    const { t } = useTranslation();
    const [columnsAdapted, setColumnsAdapted] = useState<GridColDef[]>([]);

    const [detailOpen, setDetailOpen] = useState(false);
    const [selectedReserve, setSelectedReserve] = useState<Reserve>();

    const { setEvent } = useQrCodeCheckUI();
    const columns: GridColDef[] = [
        {
            field: 'date',
            headerName: t('names:date'),
            flex: 2,
            renderCell: (params) => {
                return (
                    <Box color={params.row.canceled ? 'gray' : ''}>
                        {`${new Date(params.row.date).toLocaleDateString()}(${dayjs(params.row.date).format('dddd')})`}
                    </Box>
                );
            }
        },
        {
            field: 'asset',

            headerName: t('names:businessSite'),
            flex: 2,
            renderCell: (params) => {
                return (
                    <Box width="100%" sx={{ textWrap: 'wrap' }} color={params.row.canceled ? 'gray' : ''}>
                        {params.row.asset.name}
                    </Box>
                );
            }
        },
        {
            field: 'startTime',
            headerName: t('names:startTime'),
            flex: 3,
            renderCell: (params) => {
                return (
                    <Box color={params.row.canceled ? 'gray' : ''}>
                        {convertMinutesToTimeFromatString(params.row.startTime)}
                    </Box>
                );
            }
        },
        {
            field: 'user',
            headerName: t('names:booker'),
            flex: 3,
            renderCell: (params) => {
                return (
                    <Box color={params.row.canceled ? 'gray' : ''}>
                        {params.row.reservedPerson
                            ? `${params.row.user.name} / ${params.row.reservedPerson}(${params.row.headCount})`
                            : `${params.row.user.name}(${params.row.headCount})`}
                    </Box>
                );
            }
        },
        {
            field: 'assetType',
            headerName: t('names:type'),
            flex: 2,
            renderCell: (params) => {
                return <Box color={params.row.canceled ? 'gray' : ''}>{params.row.asset.type}</Box>;
            }
        },
        {
            field: 'confirmed',
            headerName: t('names:confirmedStatus'),
            flex: 3,
            renderCell: (params) => {
                if (params.row && params.row.asset.type == SpaceType.SERVICE) {
                    return (
                        <Box p={1}>
                            {/*<Box>
                                <Chip
                                    label={params.row.confirmed ? t('names:confirmed') : t('names:unconfirmed')}
                                    color={params.row.confirmed ? 'primary' : 'error'}
                                />
                            </Box>*/}
                            <Box>
                                <Chip
                                    label={params.row.canceled ? t('names:canceled') : t('names:confirmed')}
                                    color={params.row.canceled ? 'error' : 'primary'}
                                />
                            </Box>
                        </Box>
                    );
                } else {
                    return (
                        <Box p={1}>
                            {/*<Chip label={t('names:confirmed')} color="primary" />*/}
                            <Chip
                                label={params.row.canceled ? t('names:canceled') : t('names:confirmed')}
                                color={params.row.canceled ? 'error' : 'primary'}
                            />
                        </Box>
                    );
                }
            }
        },

        {
            field: 'control',
            headerName: '',
            flex: 1,
            renderCell: (params) => {
                const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
                const open = Boolean(anchorEl);

                const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                    setAnchorEl(event.currentTarget);
                };

                const handleClose = () => {
                    setAnchorEl(null);
                };
                return (
                    <>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                        >
                            {/*params.row && params.row.asset.type == SpaceType.SERVICE && (
                                <MenuItem
                                    onClick={async () => {
                                        const qustion = `${
                                            params.row.confirmed ? t('names:unconfirmed') : t('names:confirmed')
                                        } ${t('names:chageActive')}`;

                                        const result = await comfirmDialog({
                                            title: t('names:notification'),
                                            content: qustion,
                                            type: 'info'
                                        });
                                        if (result) {
                                            await confirmed({ id: params.row._id, confirmed: !params.row.confirmed });
                                            refetch();
                                        }
                                        handleClose();
                                    }}
                                >
                                    <Button startIcon={<CheckIcon />}>
                                        {params.row.confirmed ? t('names:unconfirmed') : t('names:confirmed')}
                                    </Button>
                                </MenuItem>
                            )*/}

                            <MenuItem
                                onClick={async () => {
                                    const qustion = `${
                                        params.row.canceled ? t('names:unconfirmed') : t('names:confirmed')
                                    } ${t('names:chageActive')}`;

                                    const result = await comfirmDialog({
                                        title: t('names:notification'),
                                        content: qustion,
                                        type: 'info'
                                    });
                                    if (result) {
                                        await canceled({ id: params.row._id, canceled: !params.row.canceled });
                                        refetch();
                                    }
                                    handleClose();
                                }}
                            >
                                <Button startIcon={params.row.canceled ? <ImportContactsIcon /> : <DoDisturbOnIcon />}>
                                    {params.row.canceled ? t('names:confirmed') : t('names:cancel')}
                                </Button>
                            </MenuItem>
                            <MenuItem
                                onClick={async () => {
                                    setSelectedReserve(params.row);
                                    setDetailOpen(true);
                                }}
                            >
                                <Button startIcon={<ListAltIcon />}>{t('names:seeDetail')}</Button>
                            </MenuItem>

                            <MenuItem
                                onClick={async () => {
                                    setEvent({
                                        event: params.row,
                                        type: 'reserve'
                                    });
                                    navigate(`/qrcheck?type=reserve&eventId=${params.row._id}`);
                                }}
                            >
                                <Button startIcon={<QrCodeScannerIcon />}>{t('names:qrCodeCheck')}</Button>
                            </MenuItem>
                        </Menu>
                        <IconButton onClick={handleClick} sx={{ p: 0 }}>
                            <MoreVertIcon />
                        </IconButton>
                    </>
                );
            }
        }
    ];

    useEffect(() => {
        if (!matches) {
            let newArray = [columns[0], columns[1], columns[2], columns[3], columns[6]];
            setColumnsAdapted(newArray);
        } else {
            setColumnsAdapted(columns);
        }
    }, [matches]);

    return (
        <div style={{ height: '100%', width: '100%' }}>
            {selectedReserve && (
                <ReserveDetailDialog
                    reserve={selectedReserve}
                    open={detailOpen}
                    onClose={() => {
                        setDetailOpen(false);
                    }}
                />
            )}
            {reserves && reserves.length > 0 && (
                <DataGrid
                    getRowHeight={() => 'auto'}
                    rows={reserves}
                    disableColumnMenu
                    columns={columnsAdapted}
                    getRowId={(row) => row._id}
                    slotProps={{
                        pagination: {
                            labelRowsPerPage: t('names:rowsPerPage')
                        }
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: { page: page, pageSize: limit }
                        },
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                                brokerId: false,
                                status: false
                            }
                        }
                    }}
                    paginationMode="server"
                    onPaginationModelChange={(pagination) => {
                        setLimit(pagination.pageSize);
                        setPage(pagination.page);
                    }}
                    rowCount={count}
                    autoHeight
                />
            )}
        </div>
    );
};
