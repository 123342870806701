import * as React from 'react';
import { useEffect, useState, useRef } from 'react';

import './index.css';
import { Box } from '@mui/material';
import { useRoutes } from 'react-router';
import { getRouters } from './router';
import { useSimpleAlert, useMainUI } from 'src/store/ui';
import { useTranslation } from 'react-i18next';
import { ENVIRONMENT } from './env';
import { SimpleAlertDialog } from 'src/page/components/SimpleAlertDialog';
import { useAuthenticated, useUserProfile, useRefreshToken } from 'src/apis/hook/user';
import utc from 'dayjs/plugin/utc'; // UTC 플러그인 로드
import timezone from 'dayjs/plugin/timezone'; // 시간대 플러그인 로드

import dayjs from 'dayjs';
dayjs.locale('ko');
dayjs.extend(utc); // UTC 플러그인 확장
dayjs.extend(timezone); // 시간대 플러그인 확장
dayjs.tz.setDefault('Asia/Seoul');

//set dayjs locale ko

const useUserActivity = (inactiveTimeout = 3600000) => {
    // 기본 값은 1시간
    const [isActive, setIsActive] = useState(true);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const { refetch, isFetching } = useRefreshToken();
    const [lastFetchTime, setLastFetchTime] = useState(Date.now());

    useEffect(() => {
        const handleActivity = () => {
            setIsActive(true);

            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            timeoutRef.current = setTimeout(() => {
                setIsActive(false);
            }, inactiveTimeout);

            // 사용자 활동이 있을 때 토큰을 갱신
            const now = Date.now();
            const timeSinceLastActive = now - lastFetchTime;
            if (isActive && !isFetching && timeSinceLastActive > 600000) {
                setLastFetchTime(Date.now());
                refetch();
            }
        };

        window.addEventListener('keydown', handleActivity);
        window.addEventListener('scroll', handleActivity);
        window.addEventListener('click', handleActivity);

        return () => {
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keydown', handleActivity);
            window.removeEventListener('scroll', handleActivity);
            window.removeEventListener('click', handleActivity);

            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [inactiveTimeout, isActive, refetch]);

    return isActive;
};

export default function App() {
    const { show, title, content: alertContent, type, setShow, onClose } = useSimpleAlert();
    const { i18n, t } = useTranslation();
    const { data: authenticated } = useAuthenticated();
    const { data: profile, refetch: refetchProfile } = useUserProfile();
    const { authenticated: uiAuthenticated, setAuthenticated: setUiAutenticated } = useMainUI();
    const { alert: simpleAlert } = useSimpleAlert();
    const content = useRoutes(getRouters(profile?.level));
    useUserActivity(10000);
    useEffect(() => {
        const lang = Object.keys(ENVIRONMENT).includes('LANGUAGE');
        let localNation = 'kr';
        if (lang) {
            localNation = ENVIRONMENT.LANGUAGE;
            i18n.changeLanguage(localNation);
        }
    }, []);

    useEffect(() => {
        if (authenticated) {
            refetchProfile();
            setUiAutenticated(true);
        } else if (!authenticated && uiAuthenticated) {
            setUiAutenticated(false);
            simpleAlert(t('names:notification'), t('names:sessionExpired'), 'info');
        }
    }, [authenticated]);

    return (
        <>
            <SimpleAlertDialog
                title={title}
                type={type}
                open={show}
                onClose={() => {
                    setShow(false);
                    if (onClose) {
                        onClose();
                    }
                }}
                content={alertContent}
            />

            <Box justifyContent="center" display="flex">
                <Box
                    sx={{
                        width: '100%',
                        height: '100svh'
                    }}
                >
                    {content}
                </Box>
            </Box>
        </>
    );
}
