import { create } from 'zustand';
import { User } from 'src/apis/type';

export interface UserStore {
    authenticated: boolean;
    profile: User | null;
    setAuthenticated: (value: boolean) => void;
    setProfile: (value: User) => void;
}

export const useUserStore = create<UserStore>((set) => ({
    authenticated: false,
    profile: null,
    setAuthenticated: (value: boolean) => set({ authenticated: value }),
    setProfile: (value: User) => set({ profile: value })
}));

export interface UserListStore {
    search: string;
    page: number;
    limit: number;
    setSearch: (value: string) => void;
    setLimit: (value: number) => void;
    setPage: (value: number) => void;
}
export const useUserListStore = create<UserListStore>((set) => ({
    search: '',
    page: 0,
    limit: 20,
    setSearch: (value: string) => set({ search: value }),
    setLimit: (value: number) => set({ limit: value }),
    setPage: (value: number) => set({ page: value })
}));
