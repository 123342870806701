import axios, { AxiosRequestConfig } from 'axios';
import { ENVIRONMENT } from '../env';
import { AssetBusinessHours, AssetBusinessHour, Room } from './type';

export class APIAsset {
    static registerAsset(form: FormData) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/asset`,
            method: 'POST',
            data: form,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    //assetId: _id string
    static insertAssetRunningHours(assetId: string, assetBHs: AssetBusinessHours) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/asset/business/runnings`,
            method: 'POST',
            data: {
                id: assetId,
                data: assetBHs
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static registerAssetRoom(form: FormData) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/asset/room`,
            method: 'POST',
            data: form,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static updateAssetRoom(form: FormData) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/asset/room`,
            method: 'PUT',
            data: form,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static getAssetNames() {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/asset/user/names`,
            method: 'GET',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static getAssetRooms(assetId: string) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/asset/rooms`,
            method: 'GET',
            params: {
                id: assetId
            },
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static insertAssetRunningHour(assetId: string, assetBH: AssetBusinessHour) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/asset/business/running`,
            method: 'POST',
            data: {
                id: assetId,
                data: assetBH
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static getAssetRunningHours(assetId: string, limit: number = 10, page: number = 0) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/asset/business/runnings`,
            method: 'GET',
            params: {
                id: assetId,
                limit,
                page
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static deleteAssetRunningHour(hourId: string) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/asset/business/running`,
            method: 'DELETE',
            data: {
                id: hourId,
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static getAssetRunningHoursCount(assetId: string) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/asset/business/runnings/count`,
            method: 'GET',
            params: {
                id: assetId
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static updateAsset(form: FormData) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/asset`,
            method: 'PUT',
            data: form,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static async getAssets(page: number, limit: number) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/asset`,
            method: 'GET',
            params: {
                page,
                limit
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static async getAssetsCount() {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/asset/count`,
            method: 'GET',
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    //_id string
    static async deleteAsset(id: string) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/asset`,
            method: 'DELETE',
            data: {
                id
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    //_id string
    static async deleteRoom(asset: string, room: string) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/asset/room`,
            method: 'DELETE',
            params: {
                asset,
                room
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }
}
