import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { ethers } from 'ethers';
dayjs.locale('ko');

export function isEthereumAddress(address: string): boolean {
    // 주소가 기본적인 0x로 시작하고 42자리가 맞는지 확인
    if (!/^0x[a-fA-F0-9]{40}$/.test(address)) {
        return false;
    }
    // 체크섬이 맞는지 확인
    return ethers.utils.isAddress(address);

}

export function convertToDayOfWeek(num: number) {
    switch (num) {
        case 0:
            return '월';
        case 1:
            return '화';
        case 2:
            return '수';
        case 3:
            return '목';
        case 4:
            return '금';
        case 5:
            return '토';
        case 6:
            return '일';
        default:
            return null;
    }
}

export function convertMinutesToTimeFromatString(minutes: number) {
    const time = dayjs()
        .set('hour', minutes / 60)
        .set('minute', minutes % 60)
        .set('second', 0);

    return time.format('HH:mm');
}

export function convertMinutesToTime(minutes?: number) {
    if (minutes && minutes >= 0) {
        const time = dayjs()
            .set('hour', minutes / 60)
            .set('minute', minutes % 60);
        return time;
    } else {
        return null;
    }
}

export function daysUntil(targetDate: Date): number {
    const now = new Date();
    now.setHours(12, 0, 0, 0);
    targetDate.setHours(12, 0, 0, 0);
    const diffInMs = targetDate.getTime() - now.getTime();
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
    return diffInDays;
}

export function formatNumberToTwoDigits(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
}

export function isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}