import axios, { AxiosRequestConfig } from 'axios';
import { ENVIRONMENT } from '../env';
import { AssetBusinessHours, AssetBusinessHour, Room } from './type';

export class APIEvent {
    static createAdminEvent(form: FormData) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/event/admin`,
            method: 'POST',
            data: form,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static updateAdminEvent(form: FormData) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/event/admin`,
            method: 'PUT',
            data: form,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static updateEventClosedStatus(eventId: string, isClosed: boolean) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/event/closed`,
            method: 'PUT',
            data: {
                id: eventId,
                isClosed
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static updateEventAvailableStatus(eventId: string, isAvailable: boolean) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/event/available`,
            method: 'PUT',
            data: {
                id: eventId,
                isAvailable
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static async getEvents(page: number, limit: number) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/event`,
            method: 'GET',
            params: {
                page,
                limit
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static async getEventsAll(search?: string, startDate?: string, endDate?: string, page?: number, limit?: number) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/event/all`,
            method: 'GET',
            params: {
                search,
                startDate,
                endDate,
                page,
                limit
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static async getEventsAllCount(search?: string, startDate?: string, endDate?: string) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/event/all/count`,
            method: 'GET',
            params: {
                search,
                startDate,
                endDate,
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static async deleteEvent(id: string) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/event`,
            method: 'DELETE',
            params: {
                id
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }
}
