import React, { useState } from 'react';
import { useCallback } from 'react';
import {
    Box,
    IconButton,
    Button,
    FormControl,
    Typography,
    TextField,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import { colors } from 'src/theme/colors';
import { useTranslation } from 'react-i18next';
import { simpleAlert } from '../components/SimpleAlertDialog';
import { useAddUserMutationQuery } from 'src/apis/hook/user';
import { UserType } from 'src/apis/type';
import { AssetSelectDialog } from 'src/page/components/AssetSelectDialog';
import { Asset } from 'src/apis/type';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

function InputLabelText({ text, required = false }: { required?: boolean; text: string }) {
    return (
        <Box display="flex">
            <span style={{ color: 'gray', fontWeight: 700 }}>
                {text} {required && <span style={{ color: 'red' }}>*</span>}
            </span>
        </Box>
    );
}

const SignUpContainer = () => {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [desc, setDesc] = useState('');
    const [password, setPassword] = useState('');
    const [repassword, setRepassword] = useState('');
    const [level, setLevel] = useState('manager');
    const [assetSelectDialogOpen, setAssetSelectDialogOpen] = useState(false);

    const { mutateAsync: signup } = useAddUserMutationQuery();
    //const [selectedAsset, setSelectedAsset] = useState<Asset>();
    const [selectedAssets, setSelectedAssets] = useState<Asset[]>();

    const registerUser = useCallback(async () => {
        try {
            if (password == repassword && password.length > 0 && email.length > 0 && name.length > 0) {
                const assets_ids = selectedAssets ? selectedAssets.map((asset) => asset._id) : [];
                const result = await signup({
                    name,
                    email,
                    desc,
                    password,
                    assets: assets_ids,
                    type: UserType.normal,
                    level: level as 'admin' | 'manager'
                });
                if (result) {
                    simpleAlert({
                        title: t('names:successRegister'),
                        content: t('names:successRegisterMessage'),
                        type: 'info'
                    });
                } else {
                    simpleAlert({
                        title: t('names:failRegister'),
                        content: t('names:failRegisterMessage'),
                        type: 'error'
                    });
                }
            } else {
                simpleAlert({ title: t('names:failRegister'), content: t('names:failRegisterMessage'), type: 'error' });
            }
        } catch (e) {
            simpleAlert({ title: t('names:failRegister'), content: t('names:failRegisterMessage'), type: 'error' });
        }
    }, [name, email, password, repassword, selectedAssets]);

    return (
        <>
            <AssetSelectDialog
                open={assetSelectDialogOpen}
                onClose={() => {
                    setAssetSelectDialogOpen(false);
                }}
                onSelect={(asset: Asset) => {
                    //check selected asset is already selected
                    if (
                        selectedAssets &&
                        selectedAssets.filter((selectedAsset) => selectedAsset._id === asset._id).length > 0
                    ) {
                        return;
                    }
                    setSelectedAssets([...(selectedAssets ? selectedAssets : []), asset]);
                }}
            />
            <Box
                height="100vh"
                sx={{ overflowY: 'scroll', backgroundColor: 'white' }}
                alignItems={'center'}
                display="flex"
                flexDirection={'column'}
                width="100%"
            >
                <Box sx={{ p: 2, borderRadius: '9px' }}>
                    <Box sx={{ p: 1 }}>
                        <Typography variant="h6" color="black" fontWeight={700}>
                            {t('names:addUser')}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ p: 1 }}>
                    <TextField
                        id="outlined-basic"
                        label={t('names:name')}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        variant="outlined"
                        size="small"
                        sx={{ width: '350px' }}
                    />
                </Box>
                <Box sx={{ p: 1, width: '350px' }}>
                    <FormControl fullWidth size="small">
                        <InputLabel>{t('names:type')}</InputLabel>
                        <Select
                            value={level}
                            label={t('names:type')}
                            onChange={(selected) => {
                                setLevel(selected.target.value as string);
                            }}
                        >
                            <MenuItem value={'admin'}>Admin</MenuItem>
                            <MenuItem value={'manager'}>Manager</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ p: 1 }}>
                    <TextField
                        id="outlined-basic"
                        label={t('names:email')}
                        value={email}
                        variant="outlined"
                        size="small"
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{ width: '350px' }}
                    />
                </Box>
                <Box sx={{ p: 1 }}>
                    <TextField
                        id="outlined-basic"
                        label={t('names:password')}
                        type="password"
                        variant="outlined"
                        value={password}
                        size="small"
                        onChange={(e) => setPassword(e.target.value)}
                        sx={{ width: '350px' }}
                    />
                </Box>
                <Box sx={{ p: 1 }}>
                    <TextField
                        id="outlined-basic"
                        label={t('names:passwordConfirm')}
                        variant="outlined"
                        type="password"
                        value={repassword}
                        size="small"
                        onChange={(e) => setRepassword(e.target.value)}
                        sx={{ width: '350px' }}
                    />
                </Box>
                {selectedAssets &&
                    selectedAssets.length > 0 &&
                    selectedAssets.map((selectedAsset, index) => {
                        return (
                            <Box
                                key={`user-selected-asset-${index}`}
                                width="calc(100% - 113px)"
                                height="40px"
                                sx={{ background: colors.mainBlue }}
                                border="1px solid #BABABA"
                                borderRadius={3}
                                alignItems="center"
                                p={1}
                                display="flex"
                                color={'white'}
                                gap={1}
                            >
                                <Box width="20%" overflow="hidden">
                                    <Typography
                                        sx={{
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 1,
                                            WebkitBoxOrient: 'vertical'
                                        }}
                                    >
                                        {selectedAsset.name}
                                    </Typography>
                                </Box>
                                <Box width="20%">
                                    <Typography
                                        sx={{
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 1,
                                            WebkitBoxOrient: 'vertical'
                                        }}
                                    >
                                        {selectedAsset.desc}
                                    </Typography>
                                </Box>
                                <Box width="50%" overflow="hidden">
                                    <Typography
                                        sx={{
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 1,
                                            WebkitBoxOrient: 'vertical'
                                        }}
                                    >
                                        {selectedAsset.address}
                                    </Typography>
                                </Box>
                                <Box width="10%" overflow="hidden">
                                    <IconButton
                                        onClick={() => {
                                            //delete selectedAssets[index];
                                            const newSelectedAssets = selectedAssets.filter((_, i) => i !== index);
                                            setSelectedAssets(newSelectedAssets);
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        );
                    })}

                <Box width="125px" ml={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon fontSize="small" />}
                        onClick={() => {
                            setAssetSelectDialogOpen(true);
                        }}
                    >
                        {t('names:selectBusinessSite')}
                    </Button>
                </Box>
                <Box mt={2}>
                    <Box mt={2} width="100%" display="flex" justifyContent="center">
                        <Button
                            sx={{
                                textTransform: 'none',
                                width: '95%',
                                borderColor: colors.main.gray02,
                                borderRadius: '8px',
                                '&:focus': {
                                    borderColor: colors.main.gray01
                                }
                            }}
                            variant="contained"
                            onClick={registerUser}
                        >
                            <Box width={'180px'}>
                                <Typography fontSize="16px" color="white">
                                    {t('names:register')}
                                </Typography>
                            </Box>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default SignUpContainer;
