export enum UserType {
    kakao = 'kakao',
    google = 'google',
    facebook = 'facebook',
    normal = 'normal'
}

export interface UserLogin {
    userIdInWallet: string;
    userVerificationToken: string;
    ecdsaPublicKey?: string;
    eddsaPublicKey?: string;
}

export enum SpaceType {
    SPACE = 'space',
    SERVICE = 'service',
    TSERVICE = 'tservice',
    ROOMSPACE = 'roomspace',
    ROOM = 'room'
}

export interface UserAdminLogin {
    email: string;
    password: string;
}

export interface KakaoInfo {
    kakaoId: string;
    kakaoProfileImage: string;
    kakaoNickName: string;
}

export interface User {
    email: string;
    name?: string;
    password?: string;
    userIdInWallet?: string;
    userWalletAddress?: string;
    type: UserType;
    phone?: string;
    desc?: string;
    level?: string;
    createdAt?: Date;
    assets?: Assets | any;
    updatedAt?: Date;
    roles?: string[];
    profileImage?: string;
    userVerificationToken?: string;
    kakaoId?: string;
    kakaoProfileImage?: string;
    kakaoNickName?: string;
    ecdsaPublicKey?: string;
    eddsaPublicKey?: string;
}

export type Users = User[];

export interface NftItem {
    timeLastUpdated: string;
    tokenId: string;
    name: string;
    description: string;
    thumbnail: string;
    image: string;
}

export type NftItems = NftItem[];

export interface KakakoAccessToken {
    access_token: string;
    token_type: string;
    refresh_token: string;
    expires_in: number; // seconds
    scope: string;
    refresh_token_expires_in: number; // seconds
}

export interface KakakoUserInfoAccount {
    email: string;
}

export interface KakakoUserInfoProps {
    nickname: string;
    profile_image: string;
    thumbnail_image: string;
}

export interface KakakoUserInfo {
    id: string;
    properties: KakakoUserInfoProps;
    kakao_account: KakakoUserInfoAccount;
}

export interface QrCode {
    code: string;
    expiry: Date;
}

export interface Asset {
    _id?: string;
    id?: string;
    inCharge?: string;
    type: string;
    name: string;
    desc: string;
    typeName?: string;
    isAvailable?: boolean;
    address?: string;
    addressDesc?: string;
    addressDetail?: string; // 실제 full 주소가 들어 간다. -> 지도에서 사용해야 한다.
    contact?: string; // 담당자 문의 사항 연락처
    backgroundImage?: string;
    detailImages?: string[];
    howToCome?: string;
    moreInfo?: string;
    runningDays?: AssetRunnings;
    maxCapa?: number;
    minCapa?: number;
    timeGap?: number;
    perDay?: number;
    usableAfterDays?: number;
    maxAllowedDays?: number;
}

export type Assets = Asset[];

export interface Archive {
    id?: string;
    title: string;
    link?: string;
    image?: string;
    createdAt?: Date;
    updatedAt?: Date;
}
export type Archives = Archive[];

export interface AssetOfftime {
    startTime?: number;
    endTime?: number;
}

export interface AssetRunning {
    wday: number;
    name: string;
    isRunning: boolean;
    startTime: number;
    endTime: number;
    offTimes?: AssetOfftime[];
}

export type AssetRunnings = AssetRunning[];

export interface AssetBusinessHour {
    _id?: any;
    id?: number;
    inCharge?: string;
    name?: string;
    date?: Date;
    desc?: string;
    isRunning?: boolean;
    startTime: number;
    endTime: number;
}

export type AssetBusinessHours = AssetBusinessHour[];

export interface Room {
    id?: string;
    name: string;
    desc?: string;
    contact?: string;
    images?: string[];
    moreInfo?: string;
    maxCapa?: number;
    minCapa?: number;
    isAvailable?: boolean;
    asset?: Asset | any;
}

export type Rooms = Room[];

export interface Event {
    _id?: any;
    id?: string;
    name?: string;
    desc?: string;
    contact?: string;
    organizer?: string;
    moreInfo?: string;
    headCount?: number;
    link?: string;
    address?: string;
    addressDesc?: string;
    date?: Date;
    startDate?: Date;
    endDate?: Date;
    startTime: number;
    endTime?: number;
    mainImage?: string;
    images?: string[];
    participants?: User[] | any;
    creator?: User | any;
    asset?: Asset | any;
    room?: Room | any;
    createdAt?: Date;
    updatedAt?: Date;
    isAvailable?: boolean;
    isClosed?: boolean;
}

export type Events = Event[];

export interface Membership {
    _id?: any;
    id?: string;
    code: string;
    title?: string;
    desc?: string;
    image?: string;
    price?: number;
    currency?: string;
    arrival?: string;
    genesis?: string;
    isAvailable?: boolean;
}

export type Memberships = Membership[];

export interface Payment {
    paymentKey?: string;
    orderId: string;
    amount?: number;
    email?: string;
    userId?: string;
    result?: string;
    createdAt?: Date;
}

export type Payments = Payment[];

export interface AssetName {
    _id?: string | any;
    id?: string;
    name: string;
}

export interface AssetReserveTimeInfo {
    _id?: string | any;
    date?: Date;
    startTime: number;
}

export type AssetReserveTimeInfos = AssetReserveTimeInfo[];

export type AssetNames = AssetName[];

export enum ReserveType {
    space = 'space',
    event = 'event',
    userEvent = 'userEvent'
}

export interface Reserve {
    _id?: any;
    desc?: string;
    contact?: string;
    headCount?: number;
    date: Date | string;
    startTime: number;
    endTime?: number;
    user?: User | any;
    asset?: Asset | any;
    assetImage?: string;
    canceled?: boolean;
    room?: Room | any;
    type?: ReserveType;
    confirmed?: boolean;
    reservedPerson?: string;
    reservedContact?: string;
}

export type Reserves = Reserve[];

export interface QrCodeValidate {
    eventId: string;
    code: string;
    type: string;
}

export interface Config {
    _id?: any;
    notification?: string;
}
