import * as React from 'react';
import { FC } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button, Typography } from '@mui/material';
import { useGetArchivesQuery, useGetArchivesCountQuery } from 'src/apis/hook/archive';
import { useArchivesUI } from 'src/store/ui';
import { Asset } from 'src/apis/type';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDeleteArchiveMutationQuery } from 'src/apis/hook/archive';
import { useSimpleAlert } from 'src/store/ui';

export const ArchivesList: FC = () => {
    const { page, limit, setPage, setLimit } = useArchivesUI();
    const { data: archives, refetch: refetchAssets } = useGetArchivesQuery(page, limit);
    const { data: count } = useGetArchivesCountQuery();

    const { mutateAsync: deleteArchive } = useDeleteArchiveMutationQuery();
    const { alert: simpleAlert } = useSimpleAlert();
    const { t } = useTranslation();

    const columns: GridColDef[] = [
        {
            field: 'date',
            headerName: '생성일',
            flex: 1,
            minWidth: 50,
            renderCell: (params) => {
                return new Date(params.row.date).toLocaleDateString();
            }
        },
        { field: 'title', headerName: t('names:title'), flex: 2, minWidth: 130 },
        { field: 'image', headerName: t('names:description'), flex: 3, minWidth: 150 },
        {
            field: 'delete',
            headerName: '',
            flex: 3,
            minWidth: 100,
            renderCell: (params) => {
                return (
                    <Button
                        onClick={async () => {
                            const confirmDelete = confirm('정말 삭제하시겠습니까?');
                            if (!confirmDelete) return;
                            const result = await deleteArchive(params.row.id);
                            if (result) {
                                simpleAlert(t('names:notification'), t('names:deleteSuccess'), 'info');
                                refetchAssets();
                            } else {
                                simpleAlert(t('names:error'), t('names:deleteFail'), 'error');
                            }
                        }}
                    >
                        {t('names:delete')}
                    </Button>
                );
            }
        }
    ];

    return (
        <div style={{ height: '100%', width: '100%' }}>
            {archives && archives.length > 0 && (
                <DataGrid
                    rows={archives}
                    rowCount={count}
                    columns={columns}
                    paginationMode="server"
                    slotProps={{
                        pagination: {
                            labelRowsPerPage: t('names:rowsPerPage')
                        }
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: { page: page, pageSize: limit }
                        },
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                                brokerId: false,
                                status: false
                            }
                        }
                    }}
                    onPaginationModelChange={(pagination) => {
                        setLimit(pagination.pageSize);
                        setPage(pagination.page);
                    }}
                    checkboxSelection
                />
            )}
        </div>
    );
};
