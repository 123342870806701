import { create } from 'zustand';

export interface ReserveListStore {
    search: string;
    startDate: Date | undefined;
    endDate: Date | undefined;
    page: number | undefined;
    limit: number | undefined;
    asset: string | undefined;
    setLimit: (value?: number) => void;
    setPage: (value?: number) => void;
    setSearch: (value?: string) => void;
    setStartDate: (value?: Date) => void;
    setEndDate: (value?: Date) => void;
    setAsset: (value?: string) => void;
}
export const useReserveListStore = create<ReserveListStore>((set) => ({
    page: 0,
    limit: 20,
    search: '',
    asset: '',
    startDate: new Date(),
    endDate: (() => {
        const newDate = new Date();
        newDate.setMonth(newDate.getMonth() + 1);
        return newDate;
    })(),
    setLimit: (value?: number) => set({ limit: value }),
    setPage: (value?: number) => set({ page: value }),
    setSearch: (value?: string) => set({ search: value }),
    setStartDate: (value?: Date) => set({ startDate: value }),
    setEndDate: (value?: Date) => set({ endDate: value }),
    setAsset: (value?: string) => set({ asset: value })
}));
