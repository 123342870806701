import axios, { AxiosRequestConfig } from 'axios';
import { ENVIRONMENT } from '../env';

export class APIArchive {
    static registerArchive(form: FormData) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/archive`,
            method: 'POST',
            data: form,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static async getArchives(page: number, limit: number) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/archive`,
            method: 'GET',
            params: {
                page,
                limit
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static async getArchivesCount() {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/archive/count`,
            method: 'GET',
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static async deleteArchive(id: string) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/archive`,
            method: 'DELETE',
            data: {
                id
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }
}